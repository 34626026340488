import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import type { NumericFormatForwardedProps } from './types';

export const NumericFormatForwarded = React.forwardRef<NumericFormatProps, NumericFormatForwardedProps>(
  function NumericFormatForwarded({ onChange, ...props }, ref) {
    return (
      <NumericFormat
        thousandSeparator
        {...props} // allow the user to override the default props
        getInputRef={ref}
        onValueChange={(values, sourceInfo) => {
          if (!sourceInfo.event) {
            return;
          }
          onChange({
            ...sourceInfo.event,
            target: {
              ...sourceInfo.event.target,
              value: values.formattedValue, // this is displayed in the UI
              floatValue: values.floatValue, // this is used in the form context
              rawValue: values.value, // this is the raw value of the input before formatting
            },
          });
        }}
      />
    );
  }
);
