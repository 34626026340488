import { isNumber, isUndefined } from 'lodash';

/**
 * @returns number
 * @description: This function will strip out any non-numeric characters from the string and return the number.
 *  Also, it will return 0 if the value is undefined or null.
 * @param value
 **/
export const stripNumber = (value?: string | number | null): number => {
  if (isNumber(value)) {
    if (isNaN(value)) return 0;
    if (!isFinite(value)) return 0;
    return value;
  }
  if (isUndefined(value) || value == null) return 0;

  return Number(value.replace(/[^0-9.]/g, ''));
};
