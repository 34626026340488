import SelectField from '@/components/SelectField';
import { SelectProps } from '@mui/material/Select';
import { Controller, ControllerProps, FieldPath, Path, UseControllerProps } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export type ControlledSelectFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues> &
  Omit<SelectProps, 'name'> & {
    name: Path<TFieldValues>;
    rules?: ControllerProps<TFieldValues, TName>['rules'];
    parseValue?: (value: any) => any;
  };

export default function ControlledSelectField<TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  defaultValue,
  rules = {},
  required,
  placeholder,
  children,
  parseValue,
  ...componentProps
}: ControlledSelectFieldProps<TFieldValues>) {
  if (required && !rules.required) {
    rules.required = 'This field is required';
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <SelectField
          required={!!rules?.required}
          error={!!error?.message}
          helperText={error?.message}
          {...componentProps}
          {...field}
          ref={field.ref}
          sx={{
            maxWidth: '100%',
            ...componentProps.sx,
            // https://github.com/mui/material-ui/issues/11069#issuecomment-1238685560
            '& .MuiSelect-select .notranslate::after': placeholder
              ? {
                  content: `"${placeholder}"`,
                  opacity: 0.42,
                }
              : {},
          }}
          onChange={(e, c) => {
            if (parseValue) {
              e.target.value = parseValue(e.target.value);
            }
            field.onChange(e);
            componentProps.onChange?.(e, c);
          }}
        >
          {children}
        </SelectField>
      )}
    />
  );
}
