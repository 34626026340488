import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { UnsavedWarningDialogProp } from './types';
import { DialogActions, DialogContainer, DialogContent, DialogTitle } from '@/components/Dialog';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { useMemo } from 'react';

export const UnsavedWarningDialog = ({
  open,
  hasError,
  saving = false,
  onClose,
  onDiscard,
  onContinueEditing,
  onSave,
  onExited,
}: UnsavedWarningDialogProp): JSX.Element => {
  const isPhoneSize = useIsPhoneSize();
  const DialogTransitionProps = useMemo(
    () => ({
      onExited,
    }),
    [onExited]
  );
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
      TransitionProps={DialogTransitionProps}
      fullScreen={isPhoneSize}
    >
      <DialogContainer>
        <DialogTitle onCloseClick={onClose} />
        <DialogContent>
          <InfoIcon fontSize={isPhoneSize ? 'medium' : 'large'} color="primary" />
          <Typography variant="h1">You have unsaved changes</Typography>
          <Box>Are you sure you want to leave without saving the changes?</Box>
        </DialogContent>

        <DialogActions>
          <Button data-testid="unsaved-warning-dialog-discard-button" onClick={onDiscard} variant="text">
            Discard Changes
          </Button>
          {hasError ? (
            <Button data-testid="unsaved-warning-dialog-continue-editing-button" onClick={onContinueEditing}>
              Continue Editing
            </Button>
          ) : (
            <LoadingButton
              disabled={hasError}
              loading={saving}
              data-testid="unsaved-warning-dialog-save-button"
              onClick={onSave}
            >
              Save
            </LoadingButton>
          )}
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
};
