import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FieldValues, Path, UseControllerProps, useController } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import { ChangeEvent, useCallback, useState } from 'react';
import FormControl from '@mui/material/FormControl';

export type ControlledTextFieldProps<TFieldValues extends FieldValues = FieldValues> =
  UseControllerProps<TFieldValues> &
    Omit<TextFieldProps, 'name'> & {
      name: Path<TFieldValues>;
      required?: boolean;
      overrideFieldOnChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    };

export default function ControlledTextField<TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  defaultValue,
  rules = {},
  overrideFieldOnChange,
  required,
  label,
  ...componentProps
}: ControlledTextFieldProps<TFieldValues>): JSX.Element {
  if (required && !rules.required) {
    rules.required = 'This field is required';
  }
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const [focused, setFocused] = useState<boolean>(false);
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null);
  const setRef = useCallback((element: HTMLInputElement | null) => {
    const inputElement = element?.querySelector('input');
    if (inputElement) {
      setInputElement(inputElement);
    }
  }, []);

  return (
    <FormControl>
      {label && (
        <InputLabel
          shrink
          disabled={componentProps.disabled}
          focused={focused}
          required={required || !!rules.required}
          htmlFor={inputElement?.id}
        >
          {label}
        </InputLabel>
      )}

      <TextField
        required={!!rules?.required}
        error={!!error?.message}
        helperText={error?.message}
        type="text"
        {...componentProps}
        {...field}
        defaultValue={defaultValue}
        inputRef={field.ref}
        ref={setRef}
        onChange={(event) => {
          if (overrideFieldOnChange) {
            overrideFieldOnChange(event);
          } else {
            field.onChange(event.target.value);
          }

          componentProps.onChange?.(event);
          setFocused(true);
        }}
        onFocus={(event) => {
          componentProps.onFocus?.(event);
          setFocused(true);
        }}
        onBlur={(event) => {
          field.onBlur?.();
          componentProps.onBlur?.(event);
          setFocused(false);
        }}
      />
    </FormControl>
  );
}
