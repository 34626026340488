import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { default as MuiSelect, SelectProps } from '@mui/material/Select';
import { ReactNode, SyntheticEvent, forwardRef, useCallback, useState } from 'react';

const SelectField = forwardRef(
  (
    {
      children,
      open = false,
      disabled = false,
      required,
      value = '',
      helperText,
      onChange,
      onClose,
      label,
      ...fieldProps
    }: SelectProps & { helperText?: ReactNode },
    ref
  ) => {
    const [isOpen, setOpen] = useState(open);
    const openSelect = useCallback(() => {
      if (!disabled) {
        setOpen(true);
      }
    }, [disabled]);
    const closeSelect = useCallback(
      (event: SyntheticEvent<Element, Event>) => {
        setOpen(false);
        onClose?.(event);
      },
      [onClose]
    );

    return (
      <FormGroup>
        {label && (
          <InputLabel focused={isOpen} disabled={disabled} required={required} onClick={openSelect}>
            {label}
          </InputLabel>
        )}
        <MuiSelect
          {...fieldProps}
          open={isOpen}
          value={value}
          disabled={disabled}
          required={required}
          onOpen={openSelect}
          onClose={closeSelect}
          onChange={onChange}
          inputRef={ref}
        >
          {children}
        </MuiSelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormGroup>
    );
  }
);

SelectField.displayName = 'SelectField';

export default SelectField;
