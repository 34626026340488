import Box from '@mui/material/Box';
import NextImage from 'next/image';
import { EmptyScreenProps } from './types';
import { styled } from '@mui/material/styles';

const Container = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const EmptyScreen = ({ useImage = true, Image, children }: EmptyScreenProps): JSX.Element => {
  return (
    <Container>
      {useImage &&
        (Image || <NextImage src="/assets/images/empty-screen.png" width={291} height={291} alt="Empty Screen" />)}
      {children}
    </Container>
  );
};
