import { ChangeEvent, ChangeEventHandler, useCallback, useMemo } from 'react';
import { type FieldValues, useController } from 'react-hook-form';
import ControlledTextField from '../ControlledTextField';
import { NumericFormatForwarded } from './NumericFormatCustom';
import type { ControlledNumberFieldProps } from './types';

/**
 * Controlled Number Field that uses react-hook-form, MUI, and react-number-format.
 *
 * ## Usage
 *
 * ### Define useForm Generic (IMPORTANT)
 *
 * This will setup the form types such as `defaultValue` prop.
 * Example setup:
 *
 * ```
 * const methods = useForm<{ numberInput: number | null | undefined }>();
 * ```
 *
 * - with Generic
 *   - `<ControlledNumberField defaultValue="1" /> // Sad TS`
 *   - `<ControlledNumberField defaultValue={1} /> // Happy TS`
 *   - `<ControlledNumberField defaultValue={null} /> // Happy TS`
 *   - `<ControlledNumberField defaultValue={undefined} /> // Happy TS`
 *   - `watch('numberInput') // TS type is number | null | undefined`
 *   - `watch('numberInput') // TS type is number | null | undefined`
 *   - `handleSubmit(({ numberInput }) => numberInput // TS type is number | null | undefined`
 * - without Generic
 *   - `<ControlledNumberField defaultValue="1" /> // ok`
 *   - `<ControlledNumberField defaultValue={1} /> // ok`
 *   - `watch('numberInput') // TS type is any`
 *
 * ### event.target
 *
 * - `event.target.value` string. this is displayed in the UI
 * - `event.target.floatValue` number. this is used in the form context
 * - `event.target.rawValue` string. this is the raw value of the input before formatting
 */
export function ControlledNumberField<TFieldValues extends FieldValues = FieldValues>(
  args: ControlledNumberFieldProps<TFieldValues>
): JSX.Element {
  const { decimalPlaces, allowNegative = true, ...childArgs } = args;
  const hasDecimalPlaces = useMemo(() => decimalPlaces && decimalPlaces !== 0, [decimalPlaces]);

  const { field } = useController({
    name: args.name,
    control: args.control,
    defaultValue: args.defaultValue,
    rules: args.rules,
  });

  const overrideFieldOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const floatValue = (event.target as any)?.floatValue as number | undefined;
      const rawValue = (event.target as any)?.rawValue as string;

      // field.onChange updates form context

      switch (true) {
        // example: 0.1 => 0. => 0.2
        case rawValue.endsWith('.'):
        // example: 0.0 => 0.01
        case rawValue.includes('.') && rawValue.endsWith('0'):
        // example: -0
        // changing the form context to 0 removes the negative sign
        case rawValue === '-0':
          return;
        default:
          // undefined does not change the form context.
          field.onChange(floatValue ?? null);
          break;
      }
    },
    [field]
  );

  return (
    <ControlledTextField
      {...childArgs}
      type="text" // don't show arrows on number input fields
      onChange={args.onChange as ChangeEventHandler<HTMLInputElement> | undefined}
      overrideFieldOnChange={overrideFieldOnChange}
      inputProps={{
        allowNegative,
        ...args.inputProps,
        decimalScale: decimalPlaces ?? 0,
        inputMode: hasDecimalPlaces ? 'decimal' : 'numeric',
        pattern: hasDecimalPlaces ? '[0-9\\.]+' : '[0-9]+',
      }}
      InputProps={{
        ...args.InputProps,
        inputComponent: NumericFormatForwarded as any,
      }}
    />
  );
}
